import http from '@/utils/http'

// 获取人员列表
export function getPersList (p) {
  return http.post('/unibu/pers/getCompPersPage', p)
}

// 删除人员
export function delPers (p) {
  return http.post('/unibu/pers/delPers', p)
}

// 新建/修改人员信息
export function savePers (p) {
  return http.post('/unibu/pers/savePers', p)
}

export function editPers (p) {
  return http.post('/unibu/pers/editPers', p)
}

// 从大的库（当前企业没有的，但大库里面有）里面获取人员信息及在职状态
export function getPersCheck (p) {
  return http.post('/unibu/pers/getPersCheck', p)
}

// 从大的库（当前企业没有的，但大库里面有）里面获取人员信息及在职状态
export function getPersDetail (p) {
  return http.post('/unibu/pers/getPersOtherDetail', p)
}

// 奖惩
export function persStatChange (p) {
  return http.post('/unibu/pers/persStatChange', p)
}

// 离职
export function persLeave (p) {
  return http.post('/unibu/pers/persLeave', p)
}

// 人员导入模版下载
export const downTemPers = p => http.postFormExcel('/unibu/pers/downloadTem', p)

// 人员导入
export const importExcelPers = p => http.postForm('/unibu/pers/importExcel', p)
