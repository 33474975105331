<template>
  <el-dialog
    v-bind="{title:'转正登记',width:'430px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="105px" :model="submitForm" :rules="rules">
      <el-form-item label="转正时间" prop="dutyDatTim">
        <el-date-picker
          v-model="submitForm.dutyDatTim"
          type="datetime"
          placeholder="选择日期时间"
          default-time="00:00:00"
          value-format="yyyy-MM-dd HH:mm:ss"
          class="w-250">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="备注" prop="dutyReason">
        <el-input type="textarea" rows="2" class="w-250" v-model.trim="submitForm.dutyReason" clearable />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { persStatChange } from '@/apis/infoManage/common'
import { persTypes, dutyTypes } from '../constants'
import XEUtils from 'xe-utils'

export default {
  props: {
    visible: { type: Boolean, default: false, require: true },
    editData: { type: Object }
  },
  computed: {
    visibleDialog: {
      set (newValue) { this.$emit('update:visible', newValue) },
      get () { return this.visible }
    }
  },
  data () {
    return {
      persTypes,
      dutyTypes,
      loading: false,
      companyList: [],
      submitForm: {
        cardID: '', // 身份证|驾驶证
        persId: '', // 人员id
        dutyDatTim: '', // 时间
        dutyReason: '', // 备注
        dutyStat: 1 // 1 正式员工（转正）
      },
      rules: {
        dutyDatTim: { required: true, trigger: 'change', message: '该属性为必填' },
        dutyReason: { required: true, trigger: 'change', message: '该属性为必填' }
      }
    }
  },
  methods: {
    submit () {
      const submitForm = { ...this.submitForm }
      const editData = this.editData
      const { done } = XEUtils.getDateDiff(editData.dutyDatTim, submitForm.dutyDatTim)
      if (!done) return this.$message.error(`转正时间 应在 ${editData.dutyDatTim} 之后。`)

      Object.keys(submitForm).forEach(key => {
        const value = submitForm[key]
        if (!value) submitForm[key] = undefined
      })
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          persStatChange(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('创建成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },

    // 初始化表单
    handlerOpen () {
      const submitForm = this.submitForm
      const editData = this.editData
      if (!editData) return

      // 只需要这两个字段
      submitForm.cardID = editData.cardID // 身份证|驾驶证
      submitForm.persId = editData.persId // 人员id
    }
  }
}
</script>
