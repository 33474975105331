<template>
  <el-dialog
    v-bind="{title:'人员详情',width:'850px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <div class="fs-16 bold mb-10">基本信息</div>
    <el-form inline label-width="105px">
      <el-row>
        <el-form-item label="身份证号" >
          <el-input class="w-250" :value="submitForm.cardID" readonly></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input class="w-250" :value="submitForm.persName" readonly></el-input>
        </el-form-item>
      </el-row>

      <el-row>
        <el-form-item label="性别">
          <el-input class="w-250" :value="submitForm.gend" readonly></el-input>
        </el-form-item>
        <el-form-item label="手机">
          <el-input class="w-250" :value="submitForm.phone" readonly></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="入职日期">
          <el-input class="w-250" :value="submitForm.dutyDatTim" readonly></el-input>
        </el-form-item>
        <el-form-item label="职位/工种">
          <el-input class="w-250" :value="submitForm.persTypeName" readonly></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="政治面貌">
          <el-input class="w-250" :value="submitForm.politics" readonly></el-input>
        </el-form-item>
        <el-form-item label="是否交社保">
          <el-input class="w-250" :value="submitForm.sociSecu?'是':'否'" readonly></el-input>
        </el-form-item>
      </el-row>

      <!-- 证书信息 -->
      <template v-if="submitForm.persType==1">
        <p class="fs-16 bold mb-15">证书信息</p>
        <el-row>
          <el-form-item label="证书类型">
            <el-input class="w-250" :value="submitForm.ccieTypeName" readonly></el-input>
          </el-form-item>
          <el-form-item label="上岗证号">
            <el-input class="w-250" :value="submitForm.ccieCode" readonly></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="证书名称">
            <el-input class="w-250" :value="submitForm.ccieName" readonly></el-input>
          </el-form-item>
        </el-row>
      </template>

      <!-- 驾照信息 -->
      <template v-if="submitForm.persType==4">
        <p class="fs-16 bold mb-15">驾照信息</p>
        <el-row>
          <el-form-item label="驾驶证号" >
            <el-input class="w-250" :value="submitForm.cardID" readonly></el-input>
          </el-form-item>
          <el-form-item label="准驾车型">
            <el-input class="w-250" :value="submitForm.liceClass" readonly></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="初次领证日期">
            <el-input class="w-250" :value="submitForm.driverStartDate" readonly></el-input>
          </el-form-item>
        </el-row>
      </template>
    </el-form>

    <div class="fs-16 bold mb-15">工作简历</div>
    <div style="max-height: 150px;width:100%;overflow:auto;">
      <wz-table :data="submitForm.expers">
        <vxe-column field="compName" title="企业名称"></vxe-column>
        <vxe-column field="dutyDatTim" title="时间"></vxe-column>
        <vxe-column field="dutyStatName" title="职责状态"></vxe-column>
        <vxe-column field="dutyReason" title="原因"></vxe-column>
      </wz-table>
    </div>

    <div class="fs-16 bold mb-15 mt-15">奖惩明细</div>
    <div style="max-height: 150px;width:100%;overflow:auto;">
      <wz-table :data="submitForm.punishs">
        <vxe-column field="compName" title="奖惩公司"></vxe-column>
        <vxe-column field="dutyDatTim" title="奖惩日期"></vxe-column>
        <vxe-column field="dutyStat" title="奖惩类型"></vxe-column>
        <vxe-column field="dutyReason" title="奖惩原因"></vxe-column>
      </wz-table>
    </div>

    <!-- <div class="fs-16 bold mb-10 mt-20">培训经历</div>
    <wz-table :data="submitForm.educs">
      <vxe-column field="educDate" title="培训日期"></vxe-column>
      <vxe-column field="educClazz" title="培训课程"></vxe-column>
      <vxe-column field="educStat" title="是否合格"></vxe-column>
      <vxe-column field="cretURL" title="证书附件"></vxe-column>
    </wz-table> -->
  </el-dialog>
</template>

<script>
import { getPersDetail } from '@/apis/infoManage/common'
import { socials } from '../constants'
export default {
  props: {
    visible: { type: Boolean, default: false, require: true },
    editData: { type: Object }
  },
  computed: {
    visibleDialog: {
      set (newValue) { this.$emit('update:visible', newValue) },
      get () { return this.visible }
    }
  },
  data () {
    return {
      socials,
      submitForm: {
        cardID: '',
        persName: '',
        driverStartDate: '',
        liceClass: '',
        phone: '',
        gend: '',
        driverYear: '',
        expers: [],
        punishs: [],
        educs: [],
        persType: '',
        persTypeName: '',
        dutyDatTim: '',
        politics: '',
        sociSecu: '',
        // 证书相关
        ccieCode: '',
        ccieTypeName: '',
        ccieName: ''
      }
    }
  },
  methods: {
    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
    },

    // 初始化表单
    handlerOpen () {
      const submitForm = this.submitForm
      const editData = this.editData
      getPersDetail({ cardID: editData.cardID }).then((res) => {
        if (res.code == 200) {
          const data = Object.assign(res.data, editData)
          Object.keys(submitForm).forEach(key => {
            const value = data[key]
            if (value !== undefined && value !== null) submitForm[key] = value
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 620px!important;
  overflow-y: auto;
}
</style>
