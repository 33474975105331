<template>
  <el-dialog
    v-bind="{title:editData?'编辑人员':'新增人员',width:'430px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="105px" :model="submitForm" :rules="rules" >
      <!-- 基本信息 -->
      <p class="fs-16 bold mb-15">基本信息</p>
      <el-form-item label="身份证号" prop="cardID">
        <el-input class="w-250" @blur="getInfo" v-model.trim="submitForm.cardID" clearable :disabled="!!editData" />
      </el-form-item>

      <el-form-item label="姓名" prop="persName">
        <el-input class="w-250" v-model.trim="submitForm.persName" clearable />
      </el-form-item>

      <el-form-item label="性别" prop="gend">
        <el-select class="w-250" v-model="submitForm.gend">
          <el-option value="男"></el-option>
          <el-option value="女"></el-option>
          <el-option value="其他"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="手机号" prop="phone">
        <el-input class="w-250" v-model.trim="submitForm.phone" clearable />
      </el-form-item>

      <el-form-item label="工种" prop="persType">
        <el-select class="w-250" v-model="submitForm.persType" :disabled="Boolean(this.type)">
          <el-option v-for="item in persTypes" :key="item.id" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>

      <el-form-item label="职位" :prop="postType==1?'ordiPosit':'seniPosit'" >
        <div class="flex w-250">
          <el-select v-model="postType" class="w-100" @change="submitForm.seniPosit=submitForm.ordiPosit=''">
            <el-option :value="1" label="普通岗位"></el-option>
            <el-option :value="2" label="重要岗位"></el-option>
          </el-select>
          <el-select v-if="postType==2" class="flex-1" v-model="submitForm.seniPosit" clearable placeholder="请选择">
            <el-option v-for="item in impPosts" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
          <el-input v-else class="flex-1" v-model.trim="submitForm.ordiPosit" placeholder="请输入"></el-input>
        </div>
      </el-form-item>

      <el-form-item label="入职日期" prop="dutyDatTim">
        <el-date-picker
          v-model="submitForm.dutyDatTim"
          type="datetime"
          placeholder="选择日期时间"
          default-time="00:00:00"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
          class="w-250">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="政治面貌" prop="politics">
        <el-select class="w-250" v-model="submitForm.politics">
          <el-option v-for="item in politicalStatus" :key="item.id" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>

      <el-form-item label="是否交社保" prop="sociSecu">
        <el-select class="w-250" v-model="submitForm.sociSecu">
          <el-option v-for="item in socials" :key="item.id" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>

      <!-- 驾驶员信息 type：4 -->
      <template v-if="submitForm.persType==4">
        <p class="fs-16 bold mb-15">驾驶员信息</p>
        <el-form-item label="驾驶证号">
          <el-input
            class="w-250"
            v-model.trim="submitForm.cardID"
            clearable
            placeholder="驾驶证号"
            disabled
          />
        </el-form-item>

        <el-form-item label="初次领证日期" prop="driverStartDate">
          <el-date-picker
            v-model="submitForm.driverStartDate"
            type="date"
            placeholder="领证日期"
            value-format="yyyy-MM-dd"
            clearable
            :picker-options="pickerOptions"
            class="w-250" >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="驾驶证等级" prop="liceClass">
          <el-select v-model="submitForm.liceClass" clearable class="w-250">
            <el-option v-for="item in driverLevels" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
        </el-form-item>
      </template>

      <!-- 专业技术人员信息 type：1 -->
      <template v-if="submitForm.persType==1">
        <p class="fs-16 bold mb-15">专业技术人信息</p>
        <el-form-item label="证件类型" prop="issuOrgGrad">
          <el-select class="w-250" v-model="submitForm.ccieType">
            <el-option v-for="item in ccieTypes" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
        </el-form-item>

        <el-form-item label="上岗证号" prop="ccieCode">
          <el-input class="w-250" v-model.trim="submitForm.ccieCode" clearable :disabled="!!editData"/>
        </el-form-item>

        <el-form-item label="证书名称" prop="ccieName">
          <el-input class="w-250" v-model.trim="submitForm.ccieName" clearable />
        </el-form-item>
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { savePers, editPers, getPersCheck } from '@/apis/infoManage/common'
import { persTypes, impPosts, politicalStatus, socials, ccieTypes, driverLevels } from '../constants'
export default {
  props: {
    visible: { type: Boolean, default: false, require: true },
    editData: { type: Object },
    type: { type: Number }
  },
  computed: {
    visibleDialog: {
      set (newValue) { this.$emit('update:visible', newValue) },
      get () { return this.visible }
    }
  },
  data () {
    return {
      driverLevels,
      ccieTypes,
      impPosts,
      persTypes, // 职位
      politicalStatus, // 政治面貌s
      socials, // 是否参保
      loading: false,
      submitForm: {
        persId: '', // 人员id
        cardID: '',
        persName: '',
        gend: '',
        phone: '',
        persType: '', // 工种
        seniPosit: '', // 重要职位
        ordiPosit: '', // 普通职位
        dutyDatTim: '', // 入职日期
        politics: '', // 政治面貌
        sociSecu: '', // 社保
        // 驾驶人员
        driverStartDate: '',
        liceClass: '',
        // 专业技术人员
        ccieType: '',
        ccieCode: '',
        ccieName: ''
      },
      postType: 1, // 区分重要职位，普通职位
      rules: {
        cardID: [
          // { required: true, trigger: 'change', message: '该属性为必填' }
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号', trigger: 'change' }
        ],
        persName: { required: true, trigger: 'change', message: '该属性为必填' },
        // gend: { required: true, trigger: 'change', message: '该属性为必填' },
        phone: [
          { required: true, trigger: 'change', message: '该属性为必填' },
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'change' }
        ],
        persType: { required: true, trigger: 'change', message: '该属性为必填' }
        // politics: { required: true, trigger: 'change', message: '该属性为必填' },
        // sociSecu: { required: true, trigger: 'change', message: '该属性为必填' },
        // ordiPosit: { required: true, trigger: 'change', message: '该属性为必填' },
        // seniPosit: { required: true, trigger: 'change', message: '该属性为必填' },
        // dutyDatTim: { required: true, trigger: 'change', message: '该属性为必填' }
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  methods: {
    async getInfo () {
      const submitForm = this.submitForm
      const cardID = submitForm.cardID
      if (!cardID) return

      const res = await getPersCheck({ cardID })
      const data = res.data
      if (res.code == 200 && data) {
        if (data.isWork != 1) { // 不在职
          Object.keys(submitForm).forEach(key => {
            const value = data[key]
            if (value) submitForm[key] = value
          })
          this.$message.success('查询到人员信息，已自动填充信息！')
        } else {
          submitForm.cardID = ''
          this.$message.error('该人员尚未离职！')
        }
      }
    },

    submit () {
      const submitForm = { ...this.submitForm }
      if (!submitForm.persId) submitForm.persId = undefined
      // 非驾驶人员
      if (submitForm.persType != 4) {
        submitForm.driverStartDate = undefined
        submitForm.liceClass = undefined
      }
      // 非专业人员
      if (submitForm.persType != 1) {
        submitForm.issuOrgGrad = ''
        submitForm.ccieCode = ''
        submitForm.ccieName = ''
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const api = this.editData ? editPers : savePers
          api(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('创建成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    // 初始化表单
    handlerOpen () {
      const submitForm = this.submitForm
      const editData = this.editData
      if (this.type) submitForm.persType = this.type
      if (!editData) return
      this.postType = editData.seniPosit ? 2 : 1
      Object.keys(submitForm).forEach(key => {
        const value = editData[key]
        if (typeof value == 'boolean' || value) submitForm[key] = value
      })
    },

    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    }
  }
}
</script>
